import Footer from "../../footer/footer";
import FormResetPassword from "./reset-password";


function ResetPassword() {
  return (
    <div>
      <FormResetPassword />
      <Footer />
    </div>
  );
}

export default ResetPassword;