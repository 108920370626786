import Footer from "../footer/footer";
import FormLogin from "./form-login";
import "./form-login.css";


function Login() {
  return (
    <div>
      <FormLogin />
      <Footer />
    </div>
  );
}

export default Login;