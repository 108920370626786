import GrowBpoWhite from "./imgs/grow-bpo-name-white.png";
import "./css/logo.css"

function LogoLogin() {
    return (
        <div className="logo">
            <img src={GrowBpoWhite} alt="Logo Grow BPO" /> 
        </div>    
    );
  }
  
  export default LogoLogin;
  